<template>
  <div class="Apps">

    <b-row v-if="true">
      <b-col cols="12">
          <br/>
        <h4 style="text-align: center; background: #c2c2c2; color: #fff; padding: 16px 30px; border-radius: 3px 3px 0 0;">Apps</h4>
      <br/>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/contacts/contactSearch')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/truck-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">TMS</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/stockSearch')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/invoice-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">INVOICING</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
         <h1 class="text-center">   <img src="@/assets/maindashboardicons/workshop-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">WORKSHOP</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
         <h1 class="text-center">   <img src="@/assets/maindashboardicons/fuel-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">FUEL</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
         <h1 class="text-center">   <img src="@/assets/maindashboardicons/calendar-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">CALENDAR</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
           <h1 class="text-center">   <img src="@/assets/maindashboardicons/more-icon-01.gif" alt=""  width="50%"></h1>
          <h6  class="mb-0 pb-0 text-center">MORE</h6>
        </b-card>
      </b-col>
    </b-row>


  
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  data: () => ({
  }),
  created(){
    this.setBreadcrumb([
      {
        text: 'Applications',
        active: true
      }
    ])
  },
  methods: {
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),
      navigateTo(route){
        this.$router.push({path: route})
      }
  }
}
</script>
